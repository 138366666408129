import {useState, useEffect} from "react";
import {useStoryblokApi} from "@storyblok/react";
import {storyblokEditable} from "@storyblok/react";
import './CocktailList.css';

const CocktailList = ({blok}) => {
    const [categories, setCategories] = useState([]);
    const storyblokApi = useStoryblokApi();

    useEffect(() => {
        let page = 1;
        let allCategories = [];
        const fetchCategories = async () => {
            try {
                let total = 0;
                do {
                    const response = await storyblokApi.get('cdn/stories', {
                        version: 'published',
                        starts_with: 'categories',  // Fetching categories
                        page: page,
                        per_page: 25
                    });
                    allCategories = [...allCategories, ...response.data.stories];
                    total = response.total;
                    page += 1;
                } while (allCategories.length < total);

                setCategories(allCategories);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchCategories();
    }, [storyblokApi]);

    const [ingredientData, setIngredientData] = useState([]);

    useEffect(() => {
        const fetchIngredients = async () => {
            try {
                let allIngredients = [];
                let page = 1;
                let response;

                do {
                    response = await storyblokApi.get('cdn/stories', {
                        version: 'draft',
                        starts_with: 'ingredients',
                        per_page: 100, // Fetch up to 100 items per page
                        page: page,
                    });

                    allIngredients = [...allIngredients, ...response.data.stories];
                    page += 1; // Move to the next page
                } while (response.data.stories.length === 100); // Continue until fewer than 100 items are returned

                setIngredientData(allIngredients);
            } catch (error) {
                console.error("Error fetching stories:", error);
            }
        };

        fetchIngredients();
    }, [storyblokApi]);

    function getIngredientNameById(id) {
        const ingredient = ingredientData.find((item) => item["uuid"] === id);
        return ingredient ? ingredient['content'] : "Unknown Ingredient";
    }

    return (
        <div {...storyblokEditable(blok)} className="cocktail-list">
            <h2 className={"list-title"} style={{textAlign: "center"}}>Cocktail List</h2>
            <div className="cocktail-grid">
                {categories.map((cat) => (
                    <div className="cocktail-item" key={cat.id}>
                        <a href={`/${cat.full_slug}`} className="cocktail-link">
                            {cat.content.Image && (
                                <img
                                    src={cat.content.Image.filename}
                                    alt={cat.content.Name}
                                    className="cocktail-image"
                                />
                            )}
                            <h3 className="cocktail-title">{cat.content.Name}</h3>
                            {/*
{cat.content.Ingridients.map((ingredient) => (
    <p key={ingredient.uuid} className="cocktail-ingredient">
        {ingredient.Amount} - {ingredient.AmountType} from {getIngredientNameById(ingredient.Ingridient).Name}
    </p>
))}
*/}
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

// <img src={cocktail.content.Image.filename} alt={cocktail.name} className="cocktail-image"/>

export default CocktailList;
