import {useStoryblokApi, storyblokEditable} from "@storyblok/react";
import {useState, useEffect, forwardRef} from "react";
import "./CocktailPage.css";

const CocktailPage = ({blok}) => {
    const storyblokApi = useStoryblokApi();
    const [ingredientData, setIngredientData] = useState([]);
    const [catsData, setCatsData] = useState([]);

    useEffect(() => {
        const fetchIngredients = async () => {
            try {
                let allIngredients = [];
                let page = 1;
                let response;

                do {
                    response = await storyblokApi.get('cdn/stories', {
                        version: 'draft',
                        starts_with: 'ingredients',
                        per_page: 100, // Fetch up to 100 items per page
                        page: page,
                    });

                    allIngredients = [...allIngredients, ...response.data.stories];
                    page += 1; // Move to the next page
                } while (response.data.stories.length === 100); // Continue until fewer than 100 items are returned

                setIngredientData(allIngredients);
            } catch (error) {
                console.error("Error fetching stories:", error);
            }
        };

        fetchIngredients();

        const fetchCats = async () => {
            try {
                let allCats = [];
                let page = 1;
                let response;

                do {
                    response = await storyblokApi.get('cdn/stories', {
                        version: 'draft',
                        starts_with: 'categories',
                        per_page: 25, // Fetch up to 100 items per page
                        page: page,
                    });

                    allCats = [...allCats, ...response.data.stories];
                    page += 1; // Move to the next page
                } while (response.data.stories.length === 100); // Continue until fewer than 100 items are returned

                setCatsData(allCats);
            } catch (error) {
                console.error("Error fetching stories:", error);
            }
        };

        fetchCats();
    }, [storyblokApi]);


    const getIngredientNameById = (id) => {
         const ingredient = ingredientData.find((item) => item["uuid"] === id);
         return ingredient ? ingredient['content'].Name : "Unknown Ingredient";
    };
    const getCategoryNameById = (id) => {
         const cat = catsData.find((item) => item["uuid"] === id);
         return cat ? cat['content'].Name : "Unknown Category";
    };

    return (
        <main {...storyblokEditable(blok)} className="cocktail-page">
            {/* Image */}
            {blok.Image && blok.Image.filename && (
                <div className="image-wrapper">
                    <img
                        src={blok.Image.filename}
                        alt={blok.Image.alt || "Cocktail Image"}
                        className="cocktail-image"
                    />
                </div>
            )}

            {/* Cocktail Name */}
            <h1 className="cocktail-name">{blok.Name || "Cocktail Name"}</h1>

            {/* Ingredients */}
            {blok.Ingridients && blok.Ingridients.length > 0 && (
                <div className="ingredients">
                    <h2>Ingredients</h2>
                    <ul>
                        {blok.Ingridients.map((ingredient) => (
                            <li key={ingredient._uid} className="ingredient-item">
                                {ingredient.Amount} {ingredient.AmountType} -{" "}
                                <b>{getIngredientNameById(ingredient.Ingridient)}</b>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {blok.Categories && blok.Categories.length > 0 && (
                <div className="ingredients">
                    <div className="ingredient-item">
                        {blok.Categories.map((cat, index) => (
                            <span key={cat._uid}>
        <b>{getCategoryNameById(cat)}</b>
                                {index < blok.Categories.length - 1 && " | "}
    </span>
                        ))}
                    </div>
                </div>
            )}

            {/* Grid for Text Fields */}
            <div className="info-grid">
                {/* Type */}
                {blok.Type && (
                    <div className="info-box">
                        <h3>Type</h3>
                        <p>{blok.Type}</p>
                    </div>
                )}
                {/* Recipe */}
                {blok.Recipe && (
                    <div className="info-box">
                        <h3>Recipe</h3>
                        <p>{blok.Recipe}</p>
                    </div>
                )}
                {/* Glass */}
                {blok.Glass && (
                    <div className="info-box">
                        <h3>Glass</h3>
                        <p>{blok.Glass}</p>
                    </div>
                )}

                {/* Notes */}
                {blok.Notes && (
                    <div className="info-box">
                        <h3>Notes</h3>
                        <p>{blok.Notes}</p>
                    </div>
                )}

                {/* Taste */}
                {blok.Taste && (
                    <div className="info-box">
                        <h3>Taste</h3>
                        <p>{blok.Taste}</p>
                    </div>
                )}
            </div>
        </main>
    );
};

export default CocktailPage;
