import { useState, useEffect } from "react";
import { useStoryblokApi } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react";
import './CocktailList.css';

const CategoriesPage = ({ blok }) => {
    const [filteredCocktails, setFilteredCocktails] = useState([]);
    const [allCocktails, setAllCocktails] = useState([]); // State to store all cocktails
    const [ingredients, setIngredients] = useState([]); // State to store all ingredients
    const [categoryID, setCategoryID] = useState("");  // Track categoryID in state
    const [loading, setLoading] = useState(true);  // Loading state
    const storyblokApi = useStoryblokApi();

    useEffect(() => {
        console.log('loading', loading);
        if (!loading) {
            const timer = setTimeout(() => {
                const badges = document.querySelectorAll('.badge');
                badges.forEach(badge => {
                    badge.style.opacity = '1';
                });
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [loading]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch categories
                const fetchCategories = async () => {
                    const response = await storyblokApi.get('cdn/stories', {
                        version: 'published',
                        starts_with: 'categories',
                        per_page: 25
                    });
                    const category = response.data.stories.find(cat => cat.content._uid === blok._uid);
                    if (category) {
                        setCategoryID(category.uuid);
                    }
                };

                await fetchCategories();

                if (categoryID) {
                    // Fetch cocktails filtered by categoryID
                    const fetchCocktails = async () => {
                        let page = 1;
                        let allCocktails = [];
                        let total = 0;

                        do {
                            const response = await storyblokApi.get('cdn/stories', {
                                version: 'published',
                                starts_with: 'cocktails',
                                page: page,
                                per_page: 25,
                                filter_query: {
                                    'Categories': { 'in': categoryID }
                                }
                            });
                            allCocktails = [...allCocktails, ...response.data.stories];
                            total = response.total;
                            page += 1;
                        } while (allCocktails.length < total);

                        setFilteredCocktails(allCocktails);
                        setAllCocktails(allCocktails);
                    };

                    await fetchCocktails();

                    const fetchIngredients = async () => {
                        let page = 1;
                        let allIngredients = [];
                        let total = 0;

                        do {
                            const response = await storyblokApi.get('cdn/stories', {
                                version: 'published',
                                starts_with: 'ingredients',
                                page: page,
                                per_page: 25
                            });
                            allIngredients = [...allIngredients, ...response.data.stories];
                            total = response.total;
                            page += 1;
                        } while (allIngredients.length < total);

                        setIngredients(allIngredients);
                    };

                    await fetchIngredients();
                }

                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [blok, categoryID, storyblokApi]);

    const checkStock = (cocktail) => {
        return !cocktail.Ingridients.some(ingredient => {
            const ingredientData = ingredients.find(item => item.uuid === ingredient.Ingridient);
            return !ingredientData || !ingredientData.content.OnStorage;
        });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    const handleSearch = (query) => {
        console.log('query', query);
        console.log('allCocktails', allCocktails);
        const filtered = allCocktails.filter(cocktail =>
            cocktail.content.Name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredCocktails(filtered);
        setAllCocktails(allCocktails);
    };

    return (
        <div {...storyblokEditable(blok)} className="cocktail-list">

            <h2 className="list-title" style={{ textAlign: "center" }}>{blok.Name}</h2>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search cocktails..."
                    className="search-input"
                    onChange={(e) => handleSearch(e.target.value)}
                />
            </div>
            <div className="cocktail-grid">
                {filteredCocktails.map((cocktail) => (
                    <div className="cocktail-item" key={cocktail.id}>
                        <a href={`/${cocktail.full_slug}`} className="cocktail-link">
                            <img
                                src={cocktail.content.Image.filename}
                                alt={cocktail.name}
                                className="cocktail-image"
                            />
                            <h3 className="cocktail-title">{cocktail.name}</h3>
                            {!checkStock(cocktail.content) && (
                                <div className="badge">Out of Stock</div>
                            )}
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CategoriesPage;
